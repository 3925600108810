.App {
  text-align: center;
  padding: 20px;
  max-width: 1600px;
  margin: auto;

}


.content {
  display: flex;
  flex-direction: row;
}

.menu {
  width: 300px;
}

.formTable {
  margin-left: 20px;
  flex: 1;
}

.menumenu {
  width: 100%;
}

.menumenuTop{
  margin-bottom: 20px;

}

.menumenuTitle {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
}


.menumenuItem {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 47px;
  background: #fafafa;
  cursor: pointer;
  position: relative;

}

.menumenuItem:hover .menumenuItemDel {
  display: block;
  /* 当鼠标移入父元素时，显示子元素 */
}

.menumenuItemDel {
  position: absolute;
  right: 0;
  top: 0;
  display: none;

}

.menumenuItemActive {
  background: #1677ff !important;
  color: #fff;
}

.login {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.menuAdminBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menuAdminBtnBtn {
  height: 30px;
  transform: translateX(11px);
}

.menuCardTitle{
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* .ant-card-body{
  padding: 0px !important;
} */

.accountManagement{
  display: flex;
  flex-direction: row;
}

.accountManagementRight{
  flex: 1;
}

.CheckboxContent{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.CheckboxContentTop{
  display: flex;
  flex-direction: row;
}

.CheckboxContentBtn{
  width: 100px;
}
